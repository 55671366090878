@import "~@ui/styles/tools";

.indicators {
    list-style: none;
    display: flex;
    gap: 10px;
    justify-content: center;

    button {
        display: inline-block;
        padding: 0;
        width: 10px;
        height: 10px;
        background: #333;
        border-radius: 50%;
        opacity: 0.25;
        cursor: pointer;
        border: none;
        outline: none;

        &.active {
            background: #b1b1b1;
            opacity: 1;
        }
    }

    @include media-tablet {
        margin-bottom: 0;
        margin-top: 10px;
    }
}